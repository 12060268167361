exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-banking-js": () => import("./../../../src/pages/banking.js" /* webpackChunkName: "component---src-pages-banking-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-business-management-js": () => import("./../../../src/pages/business-management.js" /* webpackChunkName: "component---src-pages-business-management-js" */),
  "component---src-pages-coming-soon-uk-js": () => import("./../../../src/pages/coming-soon/uk.js" /* webpackChunkName: "component---src-pages-coming-soon-uk-js" */),
  "component---src-pages-credit-js": () => import("./../../../src/pages/credit.js" /* webpackChunkName: "component---src-pages-credit-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inside-moniepoint-index-js": () => import("./../../../src/pages/inside-moniepoint/index.js" /* webpackChunkName: "component---src-pages-inside-moniepoint-index-js" */),
  "component---src-pages-inside-moniepoint-jobs-js": () => import("./../../../src/pages/inside-moniepoint/jobs.js" /* webpackChunkName: "component---src-pages-inside-moniepoint-jobs-js" */),
  "component---src-pages-ke-index-js": () => import("./../../../src/pages/ke/index.js" /* webpackChunkName: "component---src-pages-ke-index-js" */),
  "component---src-pages-ng-about-us-js": () => import("./../../../src/pages/ng/about-us.js" /* webpackChunkName: "component---src-pages-ng-about-us-js" */),
  "component---src-pages-ng-bbn-t-and-c-js": () => import("./../../../src/pages/ng/bbn-t-and-c.js" /* webpackChunkName: "component---src-pages-ng-bbn-t-and-c-js" */),
  "component---src-pages-ng-business-account-js": () => import("./../../../src/pages/ng/business-account.js" /* webpackChunkName: "component---src-pages-ng-business-account-js" */),
  "component---src-pages-ng-contact-us-js": () => import("./../../../src/pages/ng/contact-us.js" /* webpackChunkName: "component---src-pages-ng-contact-us-js" */),
  "component---src-pages-ng-cookie-policy-js": () => import("./../../../src/pages/ng/cookie-policy.js" /* webpackChunkName: "component---src-pages-ng-cookie-policy-js" */),
  "component---src-pages-ng-expense-cards-js": () => import("./../../../src/pages/ng/expense-cards.js" /* webpackChunkName: "component---src-pages-ng-expense-cards-js" */),
  "component---src-pages-ng-ims-policy-js": () => import("./../../../src/pages/ng/ims-policy.js" /* webpackChunkName: "component---src-pages-ng-ims-policy-js" */),
  "component---src-pages-ng-index-js": () => import("./../../../src/pages/ng/index.js" /* webpackChunkName: "component---src-pages-ng-index-js" */),
  "component---src-pages-ng-payment-pos-terminals-js": () => import("./../../../src/pages/ng/payment/pos-terminals.js" /* webpackChunkName: "component---src-pages-ng-payment-pos-terminals-js" */),
  "component---src-pages-ng-personal-banking-js": () => import("./../../../src/pages/ng/personal/banking.js" /* webpackChunkName: "component---src-pages-ng-personal-banking-js" */),
  "component---src-pages-ng-personal-bbn-game-js": () => import("./../../../src/pages/ng/personal/bbn-game.js" /* webpackChunkName: "component---src-pages-ng-personal-bbn-game-js" */),
  "component---src-pages-ng-personal-bbn-games-terms-of-use-js": () => import("./../../../src/pages/ng/personal/bbn-games-terms-of-use.js" /* webpackChunkName: "component---src-pages-ng-personal-bbn-games-terms-of-use-js" */),
  "component---src-pages-ng-personal-cards-js": () => import("./../../../src/pages/ng/personal/cards.js" /* webpackChunkName: "component---src-pages-ng-personal-cards-js" */),
  "component---src-pages-ng-personal-index-js": () => import("./../../../src/pages/ng/personal/index.js" /* webpackChunkName: "component---src-pages-ng-personal-index-js" */),
  "component---src-pages-ng-personal-payment-js": () => import("./../../../src/pages/ng/personal/payment.js" /* webpackChunkName: "component---src-pages-ng-personal-payment-js" */),
  "component---src-pages-ng-personal-salary-advance-js": () => import("./../../../src/pages/ng/personal/salary-advance.js" /* webpackChunkName: "component---src-pages-ng-personal-salary-advance-js" */),
  "component---src-pages-ng-privacy-policy-js": () => import("./../../../src/pages/ng/privacy-policy.js" /* webpackChunkName: "component---src-pages-ng-privacy-policy-js" */),
  "component---src-pages-ng-whistleblower-policy-js": () => import("./../../../src/pages/ng/whistleblower-policy.js" /* webpackChunkName: "component---src-pages-ng-whistleblower-policy-js" */),
  "component---src-pages-ng-working-capital-loans-js": () => import("./../../../src/pages/ng/working-capital-loans.js" /* webpackChunkName: "component---src-pages-ng-working-capital-loans-js" */),
  "component---src-pages-payments-js": () => import("./../../../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-press-featured-businesses-js": () => import("./../../../src/pages/press/featured-businesses.js" /* webpackChunkName: "component---src-pages-press-featured-businesses-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-press-logo-and-usage-js": () => import("./../../../src/pages/press/logo-and-usage.js" /* webpackChunkName: "component---src-pages-press-logo-and-usage-js" */),
  "component---src-pages-press-product-images-js": () => import("./../../../src/pages/press/product-images.js" /* webpackChunkName: "component---src-pages-press-product-images-js" */),
  "component---src-pages-press-team-pictures-js": () => import("./../../../src/pages/press/team-pictures.js" /* webpackChunkName: "component---src-pages-press-team-pictures-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-ux-terms-and-conditions-js": () => import("./../../../src/pages/ux-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-ux-terms-and-conditions-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

